import { Firestore } from "@firebase/firestore";
import AccountBoxIcon from "@mui/icons-material/AccountBoxOutlined";
import BoltOutlined from "@mui/icons-material/BoltOutlined";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import BusinessOutlined from "@mui/icons-material/BusinessOutlined";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import EditOutlined from "@mui/icons-material/EditOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import UnfoldMore from "@mui/icons-material/UnfoldMore";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Toolbar,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import axios from "axios";
import { Auth, User } from "firebase/auth";
import { useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Chat from "./Chat";
import Companies from "./Companies";
import Company from "./Company";
import ParallelDialog from "./ParallelDialog";
import Profile from "./Profile";
import { identify } from "./capture";
import { EventObj } from "./types";
import { hostname, parseError } from "./utils";

const discordDarkImg = require("./img/discord-dark.svg");
const discordLightImg = require("./img/discord-light.svg");
const darkLogo = require("./img/logo_transparent_dark.png");
const logo = require("./img/logo_transparent.png");

const eventsReducer = (state: EventObj, action: any) => {
  switch (action.type) {
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SET_INFO":
      return { ...state, error: action.info };
    default:
      throw new Error();
  }
};

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(
  ({ theme, standalone }: { theme: any; standalone: boolean }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    ...(standalone === true && { marginTop: 64 }),
  })
);

const Dashboard = ({
  auth,
  db,
  setThemeMode,
  mode,
}: {
  auth: Auth;
  db: Firestore;
  setThemeMode: Function;
  mode: string;
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<any | User>({});
  const [token, setToken] = useState<any | string>("");
  const { screenName, subScreenName, companyId, instanceId } = useParams();
  const [event, eventListener] = useReducer(eventsReducer, { error: "" });
  const [init, setInit] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("free");
  const [companies, setCompanies] = useState<Array<any>>([]);
  const [activeCompanyId, setActiveCompanyId] = useState(companyId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectCompanyOpen, setSelectCompanyOpen] = useState(false);
  const [parallelDialogOpen, setParallelDialogOpen] = useState(false);
  const [parallelDialogTitle, setParallelDialogTitle] = useState("");
  const [parallelDialogContent, setParallelDialogContent] = useState("");
  const [parallelDialogButtonText, setParallelDialogButtonText] = useState("");
  const [parallelDialogOnClick, setParallelDialogOnClick] =
    useState<Function>();
  const theme = useTheme();
  // @ts-ignore
  const standalone: boolean =
    window.navigator &&
    "standalone" in window.navigator &&
    window.navigator.standalone;

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const handleClose = (screenName: string) => {
    setDrawerOpen(false);
    navigate("/dashboard/" + screenName);
  };

  auth.onAuthStateChanged((user) => {
    if (!user) {
      window.location.replace("/login");
    } else {
      identify(user);
      setCurrentUser(user);
      user.getIdToken().then((tok) => {
        setToken(tok);
      });
    }
  });

  const getCurrentPlan = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/usage`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        if (res.data.usage.subscriptionType) {
          setCurrentPlan(res.data.usage.subscriptionType);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getCompanies = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompanies(res.data.companies);
        verifySetActiveCompany(res.data.companies);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const verifySetActiveCompany = (companies: Array<any>) => {
    if (!activeCompanyId && companies && companies.length > 0) {
      setActiveCompanyId(companies[0].id);
    }
  };

  const getActiveCompanyName = () => {
    const activeCompany = companies.find((x) => x.id === activeCompanyId);
    return activeCompany ? activeCompany.name : "";
  };

  const getToken = async () => {
    if (!currentUser) {
      return "";
    }
    return currentUser.getIdToken();
  };

  const signOutAction = () => {
    auth.signOut().then((_) => {
      window.location.replace("/login");
    });
  };

  const handleCloseError = () => {
    eventListener({ type: "SET_ERROR", error: "" });
  };

  const openDialog = (
    title: string,
    content: string,
    buttonText: string,
    onClick: Function
  ) => {
    setParallelDialogOpen(true);
    setParallelDialogTitle(title);
    setParallelDialogContent(content);
    setParallelDialogButtonText(buttonText);
    setParallelDialogOnClick(onClick);
  };

  const restoreActiveCompany = () => {
    if (activeCompanyId) return;
    const activeId = window.localStorage.getItem("activeCompanyId");
    if (activeId) {
      setActiveCompanyId(activeId);
    }
  };

  const saveActiveCompanyId = (companyId: string) => {
    window.localStorage.setItem("activeCompanyId", companyId);
    setActiveCompanyId(companyId);
    navigate(`/dashboard/companies/${companyId}`);
  };

  const parallelDialogOnClickAction = () => {
    if (parallelDialogOnClick) {
      parallelDialogOnClick();
    }
    setParallelDialogOpen(false);
  };

  const parallelDialogOnClose = () => {
    setParallelDialogOpen(false);
  };

  if (currentUser.uid && !init) {
    restoreActiveCompany();
    getCurrentPlan();
    getCompanies();
    setInit(true);
  }

  return (
    <Box>
      <ParallelDialog
        open={parallelDialogOpen}
        title={parallelDialogTitle}
        content={parallelDialogContent}
        buttonText={parallelDialogButtonText}
        onClick={parallelDialogOnClickAction}
        onClose={parallelDialogOnClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={event.error !== ""}
        autoHideDuration={6000}
        message={event.error}
        onClose={handleCloseError}
      />
      <AppBar
        position="fixed"
        elevation={0}
        open={drawerOpen}
        theme={theme}
        sx={{
          borderBottom:
            theme.palette.mode === "dark"
              ? "1px solid rgba(255, 255, 255, 0.12)"
              : "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Toolbar sx={{ ...(standalone === true && { mt: 8 }) }}>
          <IconButton
            onClick={toggleDrawer(!drawerOpen)}
            edge="start"
            color="inherit"
            size="large"
            sx={{
              ...(drawerOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="img"
            src={mode === "dark" ? darkLogo : logo}
            alt="Parallel AI  logo"
            style={{ height: 35, margin: 10 }}
          />
          <Typography style={{ flexGrow: 1 }} />
          {currentPlan === "free" && window.innerWidth > 570 && (
            <Button onClick={() => handleClose("profile")} variant="outlined">
              <BoltOutlined style={{ marginRight: 5 }} />
              Upgrade
            </Button>
          )}
          <IconButton
            sx={{ ml: 1 }}
            onClick={() =>
              window.open("https://discord.gg/55t6GQSJbM", "_blank")
            }
          >
            <Box
              component="img"
              src={mode === "dark" ? discordDarkImg : discordLightImg}
              alt="discord logo"
              style={{ height: 15 }}
            />
          </IconButton>
          <IconButton
            sx={{ ml: 1, mr: 0.5 }}
            onClick={() => setThemeMode(mode === "light" ? "dark" : "light")}
            color="inherit"
          >
            {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <IconButton onClick={() => navigate("/dashboard/profile")}>
            <Avatar alt={currentUser.displayName} src={currentUser.photoURL} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "transparent",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <DrawerHeader theme={theme} standalone={standalone}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={(e: any) => {
                setAnchorEl(e.currentTarget);
                setSelectCompanyOpen(true);
              }}
            >
              <ListItemText primary={getActiveCompanyName()} />
              <UnfoldMore />
            </ListItemButton>
            <Menu
              open={selectCompanyOpen}
              anchorEl={anchorEl}
              onClose={() => setSelectCompanyOpen(false)}
            >
              {companies.map((company) => (
                <MenuItem
                  key={company.id}
                  onClick={() => {
                    saveActiveCompanyId(company.id);
                    setSelectCompanyOpen(false);
                  }}
                  disabled={company.id === activeCompanyId}
                  selected={company.id === activeCompanyId}
                >
                  {company.name}
                  <Box sx={{ flexGrow: 1 }}></Box>
                  {company.id === activeCompanyId && <Check />}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                onClick={() => {
                  handleClose("companies");
                  setSelectCompanyOpen(false);
                }}
              >
                <ListItemIcon>
                  <EditOutlined />
                </ListItemIcon>
                <ListItemText>Edit Companies</ListItemText>
              </MenuItem>
            </Menu>
          </ListItem>
          <ListItem onClick={(event) => handleClose("company")}>
            <ListItemButton>
              <ListItemIcon>
                <BusinessOutlined />
              </ListItemIcon>
              <ListItemText primary="Company" />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={(event) => handleClose("profile")}>
            <ListItemButton>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          {currentPlan === "free" && (
            <ListItem onClick={(event) => handleClose("profile")}>
              <ListItemButton>
                <ListItemIcon>
                  <BoltOutlined />
                </ListItemIcon>
                <ListItemText primary="Upgrade" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem onClick={signOutAction}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={drawerOpen} sx={{ tp: 2 }}>
        <DrawerHeader theme={theme} standalone={standalone} />
        {screenName === "companies" && !companyId && (
          <Companies
            db={db}
            currentUser={currentUser}
            token={token}
            getToken={getToken}
            eventListener={eventListener}
            openDialog={openDialog}
          />
        )}
        {screenName === "companies" &&
          subScreenName === "chats" &&
          companyId &&
          instanceId && (
            <Chat
              db={db}
              currentUser={currentUser}
              companyId={companyId}
              id={instanceId}
              token={token}
              eventListener={eventListener}
              getToken={getToken}
              mode={mode}
            />
          )}
        {screenName === "profile" && (
          <Profile
            db={db}
            currentUser={currentUser}
            token={token}
            getToken={getToken}
            eventListener={eventListener}
            mode={mode}
          />
        )}
        {screenName === "company" &&
          activeCompanyId &&
          subScreenName !== "chats" && (
            <Company
              mode={mode}
              db={db}
              currentUser={currentUser}
              id={activeCompanyId}
              token={token}
              getToken={getToken}
              eventListener={eventListener}
              openDialog={openDialog}
              subScreenName={subScreenName}
              instanceId={instanceId}
            />
          )}
        {screenName === "companies" &&
          companyId &&
          subScreenName !== "chats" && (
            <Company
              mode={mode}
              db={db}
              currentUser={currentUser}
              id={companyId || activeCompanyId || ""}
              token={token}
              getToken={getToken}
              eventListener={eventListener}
              openDialog={openDialog}
              subScreenName={subScreenName}
              instanceId={instanceId}
            />
          )}
        {screenName === "integrations" &&
          activeCompanyId &&
          subScreenName !== "chats" && (
            <Company
              mode={mode}
              db={db}
              currentUser={currentUser}
              id={companyId || activeCompanyId || ""}
              token={token}
              getToken={getToken}
              eventListener={eventListener}
              openDialog={openDialog}
              subScreenName={"integrations"}
              instanceId={instanceId}
            />
          )}
      </Main>
    </Box>
  );
};

export default Dashboard;
