import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Description from "@mui/icons-material/Description";
import Preview from "@mui/icons-material/Preview";
import RefreshOutlined from "@mui/icons-material/RefreshOutlined";
import Source from "@mui/icons-material/Source";
import WorkHistoryOutlined from "@mui/icons-material/WorkHistoryOutlined";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useState } from "react";
import IconMenuItem from "./IconMenuItem";
import LoadingScreen from "./LoadingScreen";
import OutlineEditor from "./OutlineEditor";
import ReportViewer from "./ReportViewer";
import { modelsLabelsList } from "./constants";
import { Company } from "./types";
import { hostname, parseError } from "./utils";

export default function Projects({
  currentUser,
  getToken,
  eventListener,
  companyId,
  company,
}: {
  getToken: Function;
  eventListener: Function;
  currentUser: any | User;
  companyId: string;
  company: Company;
}) {
  const [init, setInit] = useState(false);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [projectDialogLoading, setProjectDialogLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectObjective, setProjectObjective] = useState("");
  const [projectType, setProjectType] = useState("research_report");
  const [reportViewerOpen, setReportViewerOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({ report: "" });
  const [refreshing, setRefreshing] = useState(false);
  const [folderPath, setFolderPath] = useState("/");
  const [documents, setDocuments] = useState<Array<any>>([]);
  const [documentScope, setDocumentScope] = useState("none");
  const [targetAudience, setTargetAudience] = useState("General");
  const [model, setModel] = useState(
    modelsLabelsList.filter((x) => x.writingModel)[0].name
  );
  const [outline, setOutline] = useState<any>({});
  const [outlineEditorOpen, setOutlineEditorOpen] = useState(false);
  const [webResearch, setWebResearch] = useState(true);
  const [knowledgeBase, setKnowledgeBase] = useState(false);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    fetchProjects(value);
  };

  const getDocuments = async (
    path: string,
    selectedDoc: Document | undefined = undefined
  ) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/documents`, {
        headers: { Authorization: `Bearer ${currentToken}` },
        params: { path: path, companyId: companyId },
      })
      .then((res) => {
        var documentList = res.data;
        if (selectedDoc) {
          setDocuments([selectedDoc, ...documentList]);
        } else {
          setDocuments(documentList);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const fetchProjects = async (page: number) => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/projects`, {
        params: {
          companyId,
          perPage: 25,
          page: page,
        },
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setPages(res.data.pages);
        setProjects(res.data.projects);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setInit(true);
        setLoading(false);
      });
  };

  const refreshData = async () => {
    setRefreshing(true);
    await fetchProjects(page);
    setRefreshing(false);
  };

  const previewProject = (project: any) => {
    setSelectedProject(project);
    setReportViewerOpen(true);
  };

  const GetLoadingIcon = () => {
    return projectDialogLoading ? <CircularProgress size={20} /> : <div></div>;
  };

  const submitOutline = async (e: any) => {
    e.preventDefault();
    await createProject();
  };

  const createProject = async () => {
    if (!projectName || !projectObjective || !projectType) return;
    const currentToken = await getToken();
    setProjectDialogLoading(true);
    await axios
      .post(
        `${hostname}/projects`,
        {
          name: projectName,
          objective: projectObjective,
          type: projectType,
          companyId: companyId,
          documentScope: documentScope,
          targetAudience: targetAudience,
          outline: outline,
          model: model,
          webResearch: webResearch,
          knowledgeBase: knowledgeBase,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then(() => {
        setProjectDialogOpen(false);
        setProjectName("");
        setProjectObjective("");
        setOutline({});
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setProjectDialogLoading(false);
        setOutlineEditorOpen(false);
        fetchProjects(1);
      });
  };

  const handleDocumentChange = (e: any) => {
    const selectedDoc = documents.find((x) => x.id === e.target.value);
    setDocumentScope(e.target.value);
    if (e.target.value === "root") {
      setFolderPath("/");
      getDocuments("/");
    }
    if (e.target.value === "/shared") {
      setFolderPath("/shared");
      getDocuments("/shared");
    }
    if (!selectedDoc) {
      return;
    }
    if (selectedDoc.type === "folder") {
      const newPath =
        selectedDoc.path !== "/"
          ? `${selectedDoc.path}/${selectedDoc.name}`
          : `/${selectedDoc.name}`;
      setFolderPath(newPath);
      getDocuments(newPath, selectedDoc);
    }
  };

  const deleteProject = async (project: any) => {
    const currentToken = await getToken();
    setRefreshing(true);
    await axios
      .delete(`${hostname}/projects/${project.id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        refreshData();
      });
  };

  const FormatStatus = ({ project }: { project: any }) => {
    const status = project.status
      .split("_")
      .map((x: string) => {
        return x[0].toUpperCase() + x.slice(1);
      })
      .join("");
    return status;
  };

  const formatType = (project: any) => {
    return project.type
      .split("_")
      .map((x: string) => {
        return x[0].toUpperCase() + x.slice(1);
      })
      .join("");
  };

  const restartProject = async (project: any) => {
    const currentToken = await getToken();
    console.log("Restarting project");
    axios
      .post(
        `${hostname}/projects/${project.id}/restart`,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        refreshData();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const base64ToBlob = (base64: string, mimeType: string) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  const downloadPdf = async (projectId: string) => {
    const currentToken = await getToken();
    axios
      .get(`${hostname}/projects/${projectId}/download/pdf`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        const blo = base64ToBlob(res.data.b64, res.data.mimeType);
        const url = window.URL.createObjectURL(blo);
        const a = document.createElement("a");
        a.href = url;
        a.download = res.data.filename; // Specify the file name
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const createProjectParent = async () => {
    if (projectType === "large_article") {
      await generateOutline();
    } else {
      createProject();
    }
  };

  const generateOutline = async () => {
    const currentToken = await getToken();
    setProjectDialogLoading(true);
    axios
      .post(
        `${hostname}/projects/outline`,
        {
          name: projectName,
          objective: projectObjective,
          type: projectType,
          companyId: companyId,
          documentScope: documentScope,
          targetAudience: targetAudience,
          model: model,
          webResearch: webResearch,
          knowledgeBase: knowledgeBase,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .then((res) => {
        setOutline(res.data.outline);
        setOutlineEditorOpen(true);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      })
      .finally(() => {
        setProjectDialogLoading(false);
      });
  };

  if (currentUser.uid && !init) {
    fetchProjects(page);
    getDocuments(folderPath);
  }

  if (loading) {
    return <LoadingScreen open={true} />;
  }

  return (
    <Box>
      <Button
        style={{ marginTop: 10 }}
        startIcon={<WorkHistoryOutlined />}
        onClick={() => setProjectDialogOpen(true)}
      >
        Create Project
      </Button>

      <Typography sx={{ mt: 2 }}>
        Projects is a beta feature with limited functionality at the moment.
        Please contact us if you have any questions or feedback.
      </Typography>

      <Paper style={{ marginTop: 20, marginBottom: 40 }} variant="outlined">
        {refreshing && <LinearProgress />}
        {!refreshing && <div style={{ height: 4 }}></div>}
        <IconButton
          onClick={() => refreshData()}
          style={{ float: "right", marginRight: 5 }}
        >
          <RefreshOutlined />
        </IconButton>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Name
                </TableCell>
                <TableCell>Objective</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => (
                <TableRow key={project.id}>
                  <TableCell>{project.name}</TableCell>
                  <TableCell>{project.objective}</TableCell>
                  <TableCell>{project.created}</TableCell>
                  <TableCell>
                    <FormatStatus project={project} />
                  </TableCell>
                  <TableCell>{formatType(project)}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      {project.status === "completed" ? (
                        <React.Fragment>
                          <IconButton onClick={() => previewProject(project)}>
                            <Preview />
                          </IconButton>
                        </React.Fragment>
                      ) : (
                        <div style={{ flexGrow: 1 }}></div>
                      )}
                      {/* <IconButton onClick={() => restartProject(project)}>
                        <RestartAltOutlined />
                      </IconButton> */}
                      <IconButton onClick={() => deleteProject(project)}>
                        <DeleteOutline />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination page={page} count={pages} onChange={handlePageChange} />
        </TableContainer>
      </Paper>
      <Dialog
        open={projectDialogOpen}
        onClose={() => setProjectDialogOpen(false)}
      >
        <DialogTitle>Create Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choose options for a Parallel AI project.
          </DialogContentText>
          <DialogContentText style={{ marginTop: 10, marginBottom: 10 }}>
            Use the document scope to pull internal documents into the project
            for specific subjects.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setWebResearch(e.target.checked);
                }}
                checked={webResearch}
              />
            }
            label="Web Research"
          />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  setKnowledgeBase(e.target.checked);
                }}
                checked={knowledgeBase}
              />
            }
            label="Knowledge Base"
          />
          <TextField
            style={{ marginTop: 20 }}
            label="Project Name"
            value={projectName}
            fullWidth
            onChange={(e: any) => setProjectName(e.target.value)}
          />
          <TextField
            style={{ marginTop: 20 }}
            label="Project Objective"
            value={projectObjective}
            fullWidth
            multiline
            rows={3}
            onChange={(e: any) => setProjectObjective(e.target.value)}
          />
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Project Type</InputLabel>
            <Select
              label="Project Type"
              value={projectType}
              onChange={(e) => setProjectType(e.target.value)}
            >
              <MenuItem value="research_report">Research Report</MenuItem>
              <MenuItem value="resource_report">Resource Report</MenuItem>
              <MenuItem value="outline_report">Outline Report</MenuItem>
              <MenuItem value="article">Article</MenuItem>
              <MenuItem value="large_article">
                Large Article (2000-3000 words)
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel>Model</InputLabel>
            <Select
              label="Model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            >
              {modelsLabelsList
                .filter((x) => x.writingModel)
                .filter(
                  (x) =>
                    (projectType === "article" && x.provider === "OpenAI") ||
                    projectType !== "article"
                )
                .map((model) => (
                  <MenuItem key={model.name} value={model.name}>
                    <Chip label={model.provider} style={{ marginRight: 5 }} />
                    {model.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            style={{ marginTop: 20 }}
            label="Target Audience"
            value={targetAudience}
            fullWidth
            onChange={(e: any) => setTargetAudience(e.target.value)}
          />
          {knowledgeBase && (
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <InputLabel>Document Scope</InputLabel>
              <Select
                fullWidth
                value={documentScope}
                label="Document Scope"
                onChange={handleDocumentChange}
              >
                <MenuItem key={"none"} value={"none"}>
                  None
                </MenuItem>
                <MenuItem key={"root"} value={"root"}>
                  <IconMenuItem name={"/"} icon={<Source />} />
                </MenuItem>
                <MenuItem key={"shared"} value={"/shared"}>
                  <IconMenuItem
                    name={company.name + " Shared"}
                    icon={<Source color="primary" />}
                  />
                </MenuItem>
                {documents.map((doc) => (
                  <MenuItem key={doc.id} value={doc.id}>
                    <IconMenuItem
                      name={doc.name}
                      icon={
                        doc.type === "folder" ? <Source /> : <Description />
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Chip label="5-15 Credits" style={{ marginRight: 5 }} />
          <Button onClick={createProjectParent} startIcon={<GetLoadingIcon />}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <ReportViewer
        eventListener={eventListener}
        open={reportViewerOpen}
        onClose={() => setReportViewerOpen(false)}
        id={selectedProject.id}
        content={selectedProject.report}
        title={selectedProject.name}
        downloadPdf={downloadPdf}
      />
      <OutlineEditor
        eventListener={eventListener}
        outline={outline}
        setOutline={setOutline}
        open={outlineEditorOpen}
        onClose={() => setOutlineEditorOpen(false)}
        submitOutline={submitOutline}
      />
    </Box>
  );
}
