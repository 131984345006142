import AccountTree from "@mui/icons-material/AccountTree";
import Bolt from "@mui/icons-material/Bolt";
import BusinessIcon from "@mui/icons-material/Business";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import HttpsIcon from "@mui/icons-material/Https";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import Key from "@mui/icons-material/Key";
import LockIcon from "@mui/icons-material/Lock";
import MemoryIcon from "@mui/icons-material/Memory";
import Shield from "@mui/icons-material/Shield";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import Faq from "./Faq";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import PricingBlocks from "./PricingBlocks";

function HomePage({ setThemeForOnePage }: { setThemeForOnePage: Function }) {
  const [billingPeriod, setBillingPeriod] = useState("annual");
  setThemeForOnePage("dark");
  const standalone =
    window.navigator &&
    "standalone" in window.navigator &&
    window.navigator.standalone;

  return (
    <Box>
      <Box
        style={{
          position: "fixed",
          right: 20,
          top: 10,
          zIndex: 2,
          marginTop: standalone ? 60 : 0,
        }}
      >
        <Link
          underline="hover"
          color="inherit"
          href="/signup"
          style={{ marginRight: 20 }}
        >
          Sign Up
        </Link>
        <Link underline="hover" color="inherit" href="/login">
          Login
        </Link>
      </Box>
      <HeroSection
        title="Parallel AI"
        subtitle="Build AI Employees trained on your business."
        imgSrc={require("./img/hero.jpg")}
      />
      <Box style={{ backgroundImage: "linear-gradient(#263a45, #222831)" }}>
        <Container style={{ paddingTop: 200, marginBottom: 200 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                textAlign: "center",
                marginLeft: "5vw",
                marginRight: "5vw",
              }}
            >
              <Typography variant="h5">
                Meet Parallel AI—a cutting-edge solution tailored for modern
                businesses. With Parallel AI, select the most suitable AI model
                for each specific task, ensuring unparalleled efficiency and
                accuracy. Our platform seamlessly integrates with your existing
                knowledge bases, creating AI employees who are informed and
                ready to tackle your business challenges. Whether it's
                conducting robust research projects swiftly or providing expert
                consultations on-demand, Parallel AI equips your business with
                virtual experts to chat with anytime, anywhere.
              </Typography>
            </Grid>{" "}
          </Grid>
        </Container>
      </Box>
      <Box
        style={{ backgroundImage: "linear-gradient(#222831, 75%, #393E46)" }}
      >
        <Container>
          <div style={{ marginTop: 100, paddingBottom: 100 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="h5"
                  component="h5"
                  style={{
                    background:
                      "linear-gradient(91.54deg,#4affe0 -8.45%,#ffc94a 103.78%)",
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Features
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <Bolt />
                <Typography variant="h6" component="h6">
                  GPT4, Gemini, Claude 3, Mistral
                </Typography>
                <Typography variant="body1" component="p">
                  Uncapped access to the top AI models available today. Use the
                  model that works best with your data and your business. Up to
                  200k context window.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <MemoryIcon />
                <Typography variant="h6" component="h6">
                  Long-Term Memory
                </Typography>
                <Typography variant="body1" component="p">
                  AI Employees will remember past conversations to recall vital
                  information
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <ContentPasteSearchIcon />
                <Typography variant="h6" component="h6">
                  AI Knowledge Base
                </Typography>
                <Typography variant="body1" component="p">
                  Easily upload business documents to train your AI Employees
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <TuneIcon />
                <Typography variant="h6" component="h6">
                  Customize Prompts
                </Typography>
                <Typography variant="body1" component="p">
                  Create and Edit your own AI Employee persona prompts
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <BusinessIcon />
                <Typography variant="h6" component="h6">
                  Virtual Companies
                </Typography>
                <Typography variant="body1" component="p">
                  Generate virtual companies to train AI Employees about your
                  business and what you do
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <LockIcon />
                <Typography variant="h6" component="h6">
                  Privacy
                </Typography>
                <Typography variant="body1" component="p">
                  Parallel AI has been built from the ground up to keep your
                  business data safe
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <TravelExploreIcon />
                <Typography variant="h6" component="h6">
                  Live Search Engine & Web Data
                </Typography>
                <Typography variant="body1" component="p">
                  Pull realtime news and web data into the context for even more
                  accurate results.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <IntegrationInstructionsIcon />
                <Typography variant="h6" component="h6">
                  Integrations
                </Typography>
                <Typography variant="body1" component="p">
                  Integrate with your existing tools to automate workflows and
                  increase productivity. Slack, Confluence, Google Docs,
                  Discord, and more.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ margin: 20, marginBottom: 40 }}
              >
                <AccountTree />
                <Typography variant="h6" component="h6">
                  Workflow Builder
                </Typography>
                <Typography variant="body1" component="p">
                  Build multi-step AI workflows using our drag-and-drop canvas
                  editor for running advanced AI tasks.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
      <Box
        style={{ backgroundImage: "linear-gradient(#393E46, 65%, #222831)" }}
      >
        <Container style={{ paddingTop: 100, marginBottom: 100 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                component="h5"
                style={{
                  background:
                    "linear-gradient(91.54deg,#4affe0 -8.45%,#ffc94a 103.78%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                Security and Privacy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} style={{ margin: 20, marginBottom: 40 }}>
              <Shield />
              <Typography variant="h6" component="h6">
                Privacy
              </Typography>
              <Typography variant="body1" component="p">
                Your data remains highly secure with Parallel AI; we prioritize
                enterprise-grade security protocols and never use your data for
                model training.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} style={{ margin: 20, marginBottom: 40 }}>
              <HttpsIcon />
              <Typography variant="h6" component="h6">
                Encryption
              </Typography>
              <Typography variant="body1" component="p">
                Data encryption at rest (AES-256) and in transit (TLS 1.2+)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} style={{ margin: 20, marginBottom: 40 }}>
              <Key />
              <Typography variant="h6" component="h6">
                Single Sign-On
              </Typography>
              <Typography variant="body1" component="p">
                Single sign-on (SSO) via SAML 2.0 and domain verification
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{ backgroundImage: "linear-gradient(#222831, 65%, #222831)" }}
      >
        <Container style={{ paddingTop: 100, marginBottom: 100 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                component="h5"
                style={{
                  background:
                    "linear-gradient(91.54deg,#4affe0 -8.45%,#ffc94a 103.78%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                Integrations
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ margin: 20, marginBottom: 40 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 88, marginTop: 15, marginLeft: 6 }}
                        src={require("./img/slack.png")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Slack
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Chat with your AI Employees in private or collaborative
                      team Slack channels
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 40, marginTop: 8, marginLeft: 6 }}
                        src={require("./img/discord.svg")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Discord
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Chat with your AI Employees in private or collaborative
                      team Discord channels
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 40, marginLeft: 8, marginTop: 5 }}
                        src={require("./img/gdrive.png")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Google Drive
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Sync your company documents to your AI Knowledge Base
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 51 }}
                        src={require("./img/confluence.svg")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Confluence
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Sync your company wiki to your AI Knowledge Base
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 39, marginTop: 8, marginLeft: 6 }}
                        src={require("./img/notion.svg")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Notion
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Sync your company teamspaces to your AI Knowledge Base
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 76, marginTop: 8, marginLeft: 6 }}
                        src={require("./img/zapier.svg")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Zapier
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Integrate Parallel AI into your existing business
                      applications like CRM, CMS, etc.
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    variant="outlined"
                    style={{
                      padding: 10,
                      minHeight: 160,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box style={{ height: 60 }}>
                      <img
                        style={{ width: 39, marginTop: 8, marginLeft: 6 }}
                        src={require("./img/chrome.svg")}
                      ></img>
                    </Box>
                    <Typography variant="h6" style={{ marginLeft: 5 }}>
                      Chrome Extension
                    </Typography>
                    <Typography variant="body1" style={{ marginLeft: 5 }}>
                      Chat with your AI Employees while you browse the internet
                      in Chrome
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{ backgroundImage: "linear-gradient(#222831, 65%, #393E46)" }}
      >
        <Container style={{ paddingTop: 100 }}>
          <Grid container style={{ minHeight: 470 }}>
            <Grid xs={12} sm={6} style={{ textAlign: "center" }} item>
              <div style={{ height: 400 }}>
                <ParallaxBanner
                  style={{ height: 400 }}
                  layers={[
                    { image: require("./img/hello-img.jpg"), speed: -20 },
                  ]}
                />
              </div>
            </Grid>
            <Grid xs={12} sm={6} item>
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ marginTop: 10 }}
                >
                  Join our Discord
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ margin: 20, textAlign: "center" }}
                >
                  Chat in realtime with the founders and Parallel AI community
                </Typography>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ margin: 10 }}
                  onClick={() =>
                    window.open("https://discord.gg/55t6GQSJbM", "_blank")
                  }
                >
                  Join
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        style={{ backgroundImage: "linear-gradient(#393E46, 65%, #222831)" }}
      >
        <Container style={{ paddingTop: 100, marginBottom: 100 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                component="h5"
                style={{
                  background:
                    "linear-gradient(91.54deg,#4affe0 -8.45%,#ffc94a 103.78%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                Pricing
              </Typography>
            </Grid>
            <PricingBlocks
              height={560}
              planSelector={false}
              billingPeriod={billingPeriod}
              onBillingPeriod={(v) => setBillingPeriod(v)}
              selection="free"
              onPlanSelection={() => {}}
            />
            <Grid xs={12} item style={{ textAlign: "center" }}>
              <Button
                style={{ marginTop: 20 }}
                href="/pricing"
                component={Link}
                color="primary"
                variant="outlined"
              >
                Detailed Pricing
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Faq />
      <Container style={{ marginTop: 100, marginBottom: 100 }}>
        <Grid container>
          <Grid xs={12} item style={{ textAlign: "center" }}>
            <Typography variant="h5" component="h5">
              Need a Custom Solution?
            </Typography>
          </Grid>
          <Grid xs={12} item style={{ textAlign: "center" }}>
            <Button
              style={{ marginTop: 20 }}
              href="custom-solution"
              component={Link}
              color="primary"
              variant="outlined"
            >
              Learn More
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
}

export default HomePage;
