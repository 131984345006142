import { Firestore } from "@firebase/firestore";
import ChatIcon from "@mui/icons-material/ChatOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Lock from "@mui/icons-material/Lock";
import PersonAddIcon from "@mui/icons-material/PersonAddOutlined";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { User } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChatLog from "./ChatLog";
import Collaborators from "./Collaborators";
import Documents from "./Documents";
import Images from "./Images";
import Integrations from "./Integrations";
import LoadingScreen from "./LoadingScreen";
import Projects from "./Projects";
import SearchBar from "./SearchBar";
import Workflows from "./Workflows";
import capture from "./capture";
import ChatApi from "./chatApi";
import { Chat, Company, Employee } from "./types";
import { hostname, parseError, uniqueId } from "./utils";

export default ({
  db,
  mode,
  currentUser,
  id,
  token,
  getToken,
  eventListener,
  openDialog,
  subScreenName,
  instanceId,
}: {
  db: Firestore;
  mode: string;
  currentUser: any | User;
  id: string;
  token: any | string;
  getToken: Function;
  eventListener: Function;
  openDialog: Function;
  subScreenName: string | undefined;
  instanceId: string | undefined;
}) => {
  const [company, setCompany] = useState<Company>({
    uid: "",
    id: "",
    name: "",
    type: "",
    description: "",
    employees: [],
  });
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [chats, setChats] = useState<Array<Chat>>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState<Array<string>>(["read-all"]);
  const [currentPlan, setCurrentPlan] = useState<string>("free");
  const [newEmployee, setNewEmployee] = useState<Employee>({
    uid: "",
    id: uniqueId(),
    title: "",
    name: "",
    prompt: "",
    companyId: "",
    tags: [],
    picture: "",
  });
  const [modalLoading, setModalLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const chatApi = new ChatApi();
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(subScreenName || "employees");
  chatApi.init(eventListener, getToken);
  const showUsersTab = true;

  const getCompany = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${id}`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setCompany(res.data.company);
        if (res.data.company.subscriptionType) {
          setCurrentPlan(res.data.company.subscriptionType);
        }
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
  };

  const getEmployees = async () => {
    const currentToken = await getToken();
    await axios
      .get(`${hostname}/companies/${id}/employees`, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .then((res) => {
        setEmployees(res.data.employees);
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    setInit(true);
  };

  const saveEmployee = async () => {
    setModalLoading(true);
    const currentToken = await getToken();
    await axios
      .post(`${hostname}/employees/${newEmployee.id}`, newEmployee, {
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
      })
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    await getEmployees();
    setModalLoading(false);
    setModalOpen(false);
    setEditMode(false);
    setNewEmployee({
      uid: "",
      id: uniqueId(),
      title: "",
      name: "",
      prompt: "",
      companyId: "",
      tags: [],
      picture: "",
    });
  };

  const createChat = async (employee: Employee) => {
    capture("Created new chat", {});
    const currentToken = await getToken();
    const chatId = uniqueId();
    await axios
      .post(
        `${hostname}/chats`,
        {
          ...employee,
          id: chatId,
          companyId: id,
          ts: new Date().getTime(),
          employeeId: employee.id,
        },
        {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        }
      )
      .catch((err) => {
        eventListener({
          type: "SET_ERROR",
          error: parseError(err),
        });
      });
    navigate("/dashboard/companies/" + id + "/chats/" + chatId);
  };

  const createOneEmployee = async () => {
    capture("Create new employee", {});
    setModalLoading(true);
    await chatApi.createEmployees(company, 1, 1, newEmployee.title, () => {});
    await getEmployees();
    setModalLoading(false);
    setModalOpen(false);
  };

  const deleteEmployee = async (employee: Employee) => {
    const currentToken = await getToken();
    openDialog(
      "Delete Employee",
      `Are you sure you want to delete employee ${employee.name}?`,
      "Confirm",
      () => () => {
        setLoading(true);
        axios
          .delete(`${hostname}/employees/${employee.id}`, {
            headers: {
              Authorization: `Bearer ${currentToken}`,
            },
          })
          .catch((err) => {
            eventListener({
              type: "SET_ERROR",
              error: parseError(err),
            });
          })
          .finally(() => {
            getData();
          });
      }
    );
  };

  const getData = async () => {
    await Promise.all([getCompany(), getEmployees()]);
    setLoading(false);
  };

  useEffect(() => {
    if (currentUser.uid) {
      setLoading(true);
      getData();
    }
  }, [currentUser.uid, id]);

  if (loading) {
    return <LoadingScreen open={loading} />;
  }

  return (
    <Box sx={{ pt: 2 }}>
      <Container maxWidth="xl">
        <Typography
          variant="h4"
          style={{ marginTop: 10, fontFamily: '"Cairo", sans-serif' }}
        >
          {company.name}
          <Tooltip
            style={{ float: "right", marginTop: 10 }}
            title={`Add virtual employees to staff your company with all
          of the roles needed for a successful team. Tag employees with the same
          tag as company documents they should read.

          Add company documents to give your employees relevant
          information about your business. These documents will only be
          available to the employees that share a tag with the document.

          Employees have long-term memory within a single chat so they can
          retrieve context from previous messages and documents they are tagged
          with.
          `}
          >
            <InfoOutlined />
          </Tooltip>
        </Typography>
        <SearchBar
          companyId={id}
          eventListener={eventListener}
          token={token}
          getToken={getToken}
          openDialog={openDialog}
          navigate={navigate}
          setTab={setTab}
        />
        <Dialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth={"md"}
          fullWidth={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogContent>
            {!editMode && (
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Add New Employee
                </Typography>
                <Typography style={{ marginTop: 10 }}>
                  Enter a job title and the generator will automatically create
                  a name and description for the employee.
                </Typography>
                <TextField
                  style={{ marginTop: 20 }}
                  fullWidth
                  value={newEmployee.title}
                  label="Job Title"
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, title: e.target.value })
                  }
                />
                <Autocomplete
                  multiple
                  freeSolo
                  filterSelectedOptions
                  style={{ marginTop: 20 }}
                  options={tags}
                  getOptionLabel={(option: string) => option}
                  onChange={(e, v) =>
                    setNewEmployee({ ...newEmployee, tags: v })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                      placeholder="Add Tags"
                    />
                  )}
                />
                <Button
                  variant="outlined"
                  style={{ marginTop: 20 }}
                  onClick={() => createOneEmployee()}
                >
                  Create
                  {modalLoading && (
                    <CircularProgress
                      style={{ marginLeft: 10, float: "right" }}
                      color="inherit"
                      size={20}
                    />
                  )}
                </Button>
              </div>
            )}
            {editMode && (
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Employee
                </Typography>
                <TextField
                  style={{ marginTop: 20 }}
                  fullWidth
                  value={newEmployee.name}
                  label="Name"
                  onChange={(e: any) => {
                    e.stopPropagation();
                    setNewEmployee({ ...newEmployee, name: e.target.value });
                  }}
                />
                <TextField
                  style={{ marginTop: 20 }}
                  fullWidth
                  value={newEmployee.title}
                  label="Job Title"
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, title: e.target.value })
                  }
                />
                <TextField
                  style={{ marginTop: 20 }}
                  fullWidth
                  multiline
                  minRows={3}
                  value={newEmployee.prompt}
                  label="Description"
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, prompt: e.target.value })
                  }
                />
                <Autocomplete
                  multiple
                  freeSolo
                  filterSelectedOptions
                  style={{ marginTop: 20 }}
                  options={tags}
                  getOptionLabel={(option: string) => option}
                  value={newEmployee.tags}
                  onChange={(e, v) =>
                    setNewEmployee({ ...newEmployee, tags: v })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tags"
                      placeholder="Add Tags"
                    />
                  )}
                />
                <Button
                  variant="outlined"
                  style={{ marginTop: 20 }}
                  onClick={() => saveEmployee()}
                >
                  Save
                  {modalLoading && (
                    <CircularProgress
                      style={{ marginLeft: 10, float: "right" }}
                      color="inherit"
                      size={20}
                    />
                  )}
                </Button>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Tabs
          value={tab}
          onChange={(e, v) => setTab(v)}
          style={{ marginTop: 10 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab value="employees" label="Employees" />
          <Tab value="history" label="Chat History" />
          <Tab value="documents" label="Knowledge Base" />
          <Tab
            value="projects"
            label="Projects"
            iconPosition="start"
            icon={(currentPlan === "free" && <Lock />) || <></>}
            disabled={currentPlan === "free"}
          />
          <Tab
            value="workflows"
            label="Workflows"
            iconPosition="start"
            icon={
              (currentPlan !== "Business" && currentPlan !== "Enterprise" && (
                <Lock />
              )) || <></>
            }
            disabled={
              currentPlan !== "Business" && currentPlan !== "Enterprise"
            }
          />
          <Tab
            value="images"
            label="Images"
            iconPosition="start"
            icon={(currentPlan === "free" && <Lock />) || <></>}
            disabled={currentPlan === "free"}
          />
          <Tab
            value="integrations"
            label="Integrations"
            iconPosition="start"
            icon={
              (currentPlan !== "Business" && currentPlan !== "Enterprise" && (
                <Lock />
              )) || <></>
            }
            disabled={
              currentPlan !== "Business" && currentPlan !== "Enterprise"
            }
          />
          <Tab
            value="collaborators"
            label="Collaborators"
            iconPosition="start"
            icon={
              (currentPlan !== "Business" && currentPlan !== "Enterprise" && (
                <Lock />
              )) || <></>
            }
            disabled={
              currentPlan !== "Business" && currentPlan !== "Enterprise"
            }
          />
        </Tabs>
        <Divider />
        <div hidden={tab !== "employees"}>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => {
              setEditMode(false);
              setNewEmployee({
                uid: "",
                id: uniqueId(),
                title: "",
                name: "",
                prompt: "",
                companyId: "",
                tags: [],
                picture: "",
              });
              setModalOpen(true);
            }}
            startIcon={<PersonAddIcon />}
          >
            Create Employee
          </Button>
          {employees.length > 0 && (
            <Box style={{ marginTop: 20, marginBottom: 20 }}>
              <Grid container spacing={3}>
                {employees.map((employee) => (
                  <Grid item key={employee.id} xs={12} md={3}>
                    <Paper
                      variant="outlined"
                      onClick={() => createChat(employee)}
                      style={{
                        padding: 10,
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={employee.picture}
                        style={{ marginBottom: 10, height: 68, width: 68 }}
                      ></Avatar>
                      <Typography
                        variant="h6"
                        style={{
                          overflow: "hidden",
                          height: 28,
                          marginBottom: 3,
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          lineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {employee.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          overflow: "hidden",
                          height: 24,
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          lineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {employee.title}
                      </Typography>
                      <Divider style={{ marginTop: 10, marginBottom: 0 }} />
                      <div style={{ height: 58, overflow: "hidden" }}>
                        <Typography variant="caption">Tags:</Typography>
                        <Stack direction="row" spacing={1}>
                          {(employee.tags || []).map((tag) => (
                            <Chip key={tag} size="small" label={tag} />
                          ))}
                          {!employee.tags ||
                            (employee.tags.length === 0 && (
                              <Typography variant="caption">None</Typography>
                            ))}
                        </Stack>
                      </div>
                      <div
                        style={{
                          height: 143,
                          overflow: "hidden",
                          marginBottom: 10,
                          display: "-webkit-box",
                          WebkitLineClamp: 7,
                          lineClamp: 7,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        <Typography variant="body2">
                          {employee.prompt}
                        </Typography>
                      </div>
                      <Button
                        onClick={(e: any) => {
                          e.stopPropagation();
                          createChat(employee);
                        }}
                      >
                        <ChatIcon sx={{ mr: 1 }} /> Chat
                      </Button>
                      <Button
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setEditMode(true);
                          setNewEmployee(employee);
                          setModalOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={(e: any) => {
                          e.stopPropagation();
                          deleteEmployee(employee);
                        }}
                      >
                        Delete
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </div>
        <div hidden={tab !== "history"}>
          <ChatLog
            style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
            companyId={id}
            getToken={getToken}
            currentUser={tab === "history" ? currentUser : {}}
            perPage={30}
            currentChatId={null}
            eventListener={eventListener}
          />
        </div>
        <div hidden={tab !== "documents"}>
          <Documents
            db={db}
            currentUser={tab === "documents" ? currentUser : {}}
            companyId={id}
            token={token}
            getToken={getToken}
            documentId={instanceId}
            eventListener={eventListener}
            openDialog={openDialog}
            setTab={setTab}
          />
        </div>
        {currentPlan !== "free" && (
          <div hidden={tab !== "projects"}>
            <Projects
              currentUser={tab === "projects" ? currentUser : {}}
              company={company}
              companyId={id}
              getToken={getToken}
              eventListener={eventListener}
            />
          </div>
        )}
        {currentPlan !== "free" && (
          <div hidden={tab !== "images"}>
            <Images
              currentUser={tab === "images" ? currentUser : {}}
              companyId={id}
              getToken={getToken}
              eventListener={eventListener}
            />
          </div>
        )}
        {(currentPlan === "Business" || currentPlan === "Enterprise") && (
          <div hidden={tab !== "integrations"}>
            <Integrations
              currentUser={tab === "integrations" ? currentUser : {}}
              companyId={id}
              db={db}
              token={token}
              getToken={getToken}
              eventListener={eventListener}
            />
          </div>
        )}
        {(currentPlan === "Business" || currentPlan === "Enterprise") && (
          <div hidden={tab !== "collaborators"}>
            <Collaborators
              openDialog={openDialog}
              company={company}
              companyId={id}
              getToken={getToken}
              currentUser={tab === "collaborators" ? currentUser : {}}
              eventListener={eventListener}
            />
          </div>
        )}
        {(currentPlan === "Business" || currentPlan === "Enterprise") && (
          <div hidden={tab !== "workflows"}>
            <Workflows
              mode={mode}
              currentUser={tab === "workflows" ? currentUser : {}}
              company={company}
              companyId={id}
              getToken={getToken}
              eventListener={eventListener}
              currentPlan={currentPlan}
            />
          </div>
        )}
      </Container>
    </Box>
  );
};
