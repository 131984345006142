import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";

const SelectEmployeeDialog = ({
  open,
  title,
  content,
  buttonText,
  onSubmit,
  employees,
  onClose,
}: {
  open: boolean;
  title: string;
  content: string;
  buttonText: string;
  employees: Array<any>;
  onSubmit: Function;
  onClose: Function;
}) => {
  const [employeeId, setEmployeeId] = useState("");
  const submitClicked = () => {
    if (!employeeId) return;
    const employee = employees.find((x) => x.id === employeeId);
    onSubmit(employee);
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <FormControl fullWidth style={{ marginTop: 10 }}>
          <InputLabel id="select-employee">Employee</InputLabel>
          <Select
            labelId="select-employee"
            id="select-employee-select"
            value={employeeId}
            label="Employee"
            onChange={(e: any) => setEmployeeId(e.target.value)}
          >
            {employees.map((employee: any) => (
              <MenuItem key={employee.id} value={employee.id}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Avatar
                    src={employee.picture}
                    sx={{ mr: 1, height: 32, width: 32 }}
                  ></Avatar>
                  <Typography>
                    {employee.name} ({employee.title})
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={submitClicked} autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectEmployeeDialog;
